import React from 'react';
import GenericModalLayout from './GenericModalLayout';

export default ({
  switchNetworkCallback,
  network,
}: {
  switchNetworkCallback: () => void,
  network: {name: string; symbol?: string}
}) => (
  <GenericModalLayout
    titleLabel={'Incorrect network selected!'}
    onConfirm={switchNetworkCallback}
    onConfirmLabel={`Switch to ${network.name}${network.symbol ? ` ${network.symbol}` : ''}`}
  >
    <div className="max-w-[34rem] text-center flex flex-col gap-2">
      <div className={'font-bold'}>It seems that you have disconnected from the {network.name} network.</div>
      <div>To ensure correct operation of Gemhunters, please switch back to {network.name}!</div>
    </div>
  </GenericModalLayout>
);

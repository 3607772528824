import React from 'react';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import {
  BERA_ATTRIBUTES,
} from '../../../utils/consts';
import PerClassInventoryItemsGridContainer from './PerClassInventoryItemsGridContainer';
import BeraPreviewContainer from './BeraPreviewContainer';
import useBeraAttributes from './useBeraAttributes';
import EquipmentStats from './EquipmentStats';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';
import GenericSearchParams from '../../GenericSearchParams/GenericSearchParams';
import {
  ARMORY_EQUIPMENT_FILTER_BY,
  ARMORY_EQUIPMENT_SORT_BY,
} from '../../../utils/sortAndFilters/definitions/armoryEquipment';

export default () => {
  const {
    getBeraOriginalAttributes,
    getBeraNewAttributes,
    getBeraId,
    setCurrentlyHoveredItem,
    currentlyHoveredItem,
    setBeraNewAttribute,
    resetBeraNewAttributes,
    equipNewAttributes,
    shouldRender,
  } = useBeraAttributes();

  if (!shouldRender) {
    return null;
  }

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={<GenericMainScreenHeader
          goBackToLabel="Go back"
          goBackTo="/inventory/team?sortBy=gearScore.descending"
          tabs={[{
            label: 'ARMORY',
          }]}
        />}
        extraClassName="lg:!flex-row"
      >
        <BeraPreviewContainer
          getBeraOriginalAttributes={getBeraOriginalAttributes}
          getBeraNewAttributes={getBeraNewAttributes}
          getBeraId={getBeraId}
          currentlyHoveredItem={currentlyHoveredItem}
          setBeraNewAttribute={setBeraNewAttribute}
          resetBeraNewAttributes={resetBeraNewAttributes}
          equipNewAttributes={equipNewAttributes}
        />
        <EquipmentStats
          getBeraOriginalAttributes={getBeraOriginalAttributes}
          getBeraNewAttributes={getBeraNewAttributes}
          currentlyHoveredItem={currentlyHoveredItem}
        />
        <div className="flex flex-col lg:basis-2/5">
          <span className="px-4 lg:p-8 lg:pb-0 text-3xl text-white whitespace-nowrap">Equipment</span>
          <GenericSearchParams
            filtersToDisplay={ARMORY_EQUIPMENT_FILTER_BY}
            sortByToDisplay={ARMORY_EQUIPMENT_SORT_BY}
          />
          <PerClassInventoryItemsGridContainer
            beraClass={getBeraOriginalAttributes()[BERA_ATTRIBUTES.Class]}
            withOverflowVisibleOnMobile
            onInventoryItemHover={setCurrentlyHoveredItem}
            onInventoryItemClick={(itemToEquip) => {
              setBeraNewAttribute({
                type: itemToEquip.ItemGearType,
                value: itemToEquip,
              });
            }}
            getBeraNewAttributes={getBeraNewAttributes}
          />
        </div>
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};

import React from 'react';

const gradientWrapperStyleMap = {
  generic: 'from-gray-300/70 to-gray-700/70',
  genesisSpecial: 'from-indigo-600 via-sky-600 to-emerald-600',
  evolvedRegular: 'bg-gradient-to-tr from-violet-500 to-orange-300',
  nakedGray: 'from-gray-300/70 to-gray-700/70',
  none: '',
} as const;

const getGradientWrapperStyle = (gradientWrapperStyle: string) => gradientWrapperStyleMap[gradientWrapperStyle as keyof typeof gradientWrapperStyleMap] ??
 gradientWrapperStyleMap.generic;

export default ({
  children,
  extraClassname,
  disabled,
  shouldChangeBorderColorOnHover,
  shouldIncreaseScaleOnHover,
  shouldDisplayPointerCursor,
  borderWidthInPx = 3,
  withImageBgGlow,
  onClick,
  gradientWrapperStyle = 'generic',
}: {
  children: React.ReactNode
  extraClassname?: string
  disabled?: boolean
  shouldChangeBorderColorOnHover?: boolean
  shouldIncreaseScaleOnHover?: boolean
  shouldDisplayPointerCursor?: boolean
  borderWidthInPx?: number,
  withImageBgGlow?: string
  onClick?: () => void
  gradientWrapperStyle?: string
}) => (
  <div className={`
      relative
      bg-gradient-to-b rounded-lg shadow-lg
      ${getGradientWrapperStyle(gradientWrapperStyle)}
      ${!disabled && shouldChangeBorderColorOnHover && 'hover:from-gray-200 hover:to-gray-200'}
      ${shouldDisplayPointerCursor && 'cursor-pointer'}
      ${shouldIncreaseScaleOnHover && 'hover:scale-110 transition-transform'} 
      ${extraClassname}
    `}
    style={{
      padding: `${borderWidthInPx}px`,
    }}
    onClick={onClick}
  >
    {children}
    {withImageBgGlow && (
      <div
        style={{
          backgroundImage: `url('${withImageBgGlow}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // transform: 'scale(1.2)',
        }}
        className="absolute left-0 top-0 right-0 bottom-0 bg-cover blur-2xl -z-10"
      />
    )}
  </div>
);

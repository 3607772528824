import Cookies from 'js-cookie';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { AUTH_COOKIE } from '../../../utils/consts';
import { AccountState, initialState } from './accountSlice';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import { RootState } from '../../store';
import endpointUrl from '../../../utils/endpointUrl';
import bgMusicService from '../../../utils/BgMusicService';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import { ExtraReducerGenericArg, FetcherError } from '../../../utils/additionalTypes';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';

// eslint-disable-next-line import/prefer-default-export
export const accountReducers = {
  setWalletAddress: (state: AccountState, { payload }: PayloadAction<string>) => {
    state.walletAddress = payload;
  },
  setSignedIn: (state: AccountState, { payload }: PayloadAction<boolean>) => {
    state.isSignedIn = payload;
  },
  setOwnedGems: (state: AccountState, { payload }: PayloadAction<number>) => {
    state.ownedGems = payload;
  },
  logOut: () => {
    Cookies.remove(AUTH_COOKIE);

    bgMusicService.stopBgMusic();

    return {
      ...initialState,
      isSignedIn: false,
    };
  },
};

export const accountExtraReducers: ExtraReducers = {
  signIn: createExtraReducer<AccountState, {
    jwt: string
  }, {
    signature: string,
    plainText: string
  }>({
    reducer: {
      name: 'account/signIn',
      callbackFn: async ({ signature, plainText }, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          return await fetchGemhuntersApi(`${endpointUrl}/api/users/sign`, {
            method: 'POST',
            body: JSON.stringify({
              walletAddress,
              signature,
              plaintext: plainText,
            }),
          });
        } catch (error) {
          // handle bad nonce, re-initialize the login process
          if ((error as FetcherError).status === 400) {
            window.location.href = '/';
          }

          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload: { jwt } }) => {
      Cookies.set(AUTH_COOKIE, jwt);
      state.isSignedIn = true;
    },
  }),
  getAccountData: createExtraReducer<AccountState, {
    experience: number | null;
    level: number | null;
    expToNextLevel: number | null;
  }, ExtraReducerGenericArg>({
    reducer: {
      name: 'acconut/getAccountData',
      callbackFn: async (_, { rejectWithValue }) => {
        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/users/me`, {
            method: 'POST',
          });

          if (res.level !== undefined && res.expToNextLevel !== undefined && res.experience !== undefined) {
            const { experience, level, expToNextLevel } = res;

            return {
              experience, level, expToNextLevel,
            };
          }

          // handle when BE does not support account leveling
          return {
            experience: null,
            level: null,
            expToNextLevel: null,
          };
        } catch (error) {
          // fail implicit if 400/500
          console.warn(error);

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  }),
};

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDisconnect, useSignMessage, useConnect } from 'wagmi';
import InitScreen from './InitScreen';
import { fetchGemhuntersApi } from '../../utils/fetcher';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountExtraReducers } from '../../store/slices/account/accountReducers';
import { missionsExtraReducers } from '../../store/slices/missions/missionsReducers';
import { berasExtraReducers } from '../../store/slices/beras/berasReducers';
import { inventoryExtraReducers } from '../../store/slices/inventory/inventoryReducers';
import { shopExtraReducers } from '../../store/slices/shop/shopReducers';
import { leaderboardExtraReducers } from '../../store/slices/leaderboard/leaderboardReducers';
import endpointUrl from '../../utils/endpointUrl';
import { accountSlice } from '../../store/slices/account/accountSlice';
import trackJSService from '../../utils/trackJSService';

export default () => {
  const [signInData, setSignInData] = useState<{
    signature?: `0x${string}`,
    plainText?: string
  } | null>(null);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state) => state.account.walletAddress);
  const isSignedIn = useAppSelector((state) => state.account.isSignedIn);

  const { disconnect } = useDisconnect({
    mutation: {
      onSuccess: () => {
        dispatch(accountSlice.actions.logOut());
      },
    },
  });

  const { signMessage } = useSignMessage({
    mutation: {
      onError: (...error) => {
        trackJSService.trackWithUserInfo('init singature')(error);
        // eslint-disable-next-line no-console
        console.warn('wallet connector not available, disconnect!');
        disconnect();
      },
      onSuccess: (data) => setSignInData({
        signature: data,
        ...signInData,
      }),
    },
  });

  useConnect({
    mutation: {
      onError: (...error) => {
        trackJSService.trackWithUserInfo('on connect')(error);
        // eslint-disable-next-line no-console
        console.warn('wallet connector not available, disconnect!');
        disconnect();
      },
    },
  });

  useEffect(() => {
    if (!walletAddress) {
      navigate('/welcome');
    } else if (!isSignedIn) {
      (async () => {
        const resp = await fetchGemhuntersApi(`${endpointUrl}/api/users/nonce`, {
          method: 'POST',
          body: JSON.stringify({ walletAddress }),
        });

        setSignInData({
          plainText: resp.message,
        });

        signMessage({ message: resp.message });
      })();
    }
  }, [walletAddress, isSignedIn]);

  useEffect(() => {
    if (signInData?.signature && signInData?.plainText && !isSignedIn) {
      dispatch(accountExtraReducers.signIn.reducer(signInData));
    }
  }, [signInData, isSignedIn]);

  useEffect(() => {
    if (isSignedIn) {
      (async () => {
        await Promise.allSettled([
          dispatch(accountExtraReducers.getAccountData.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(missionsExtraReducers.getMissions.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(berasExtraReducers.getBeras.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(inventoryExtraReducers.getOwnedItems.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(shopExtraReducers.getShopItems.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(leaderboardExtraReducers.getLeaderboardEntries.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
          dispatch(missionsExtraReducers.getMissionLogs.reducer({
            failImplicit: true,
          })), // improve typing for extra reducers
        ]);

        navigate(locationState?.redirectUrlAfterAuth ?? '/missions/available'); // TODO validate if data is correct?
      })();
    }
  }, [walletAddress, isSignedIn]);

  return <InitScreen />;
};

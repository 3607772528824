import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  MissionsIconSvg, InventoryIconSvg, GemShopIconSvg, LeaderboardIconSvg, MissionLogsSvg,
} from '../SvgIcons';

const svgIconStyle = 'aspect-square p-[0.6rem] sm:p-0 h-14';
const linkStyle = 'group flex flex-col items-center sm:gap-2 font-bold text-sm basis-1/5 min-w-0';
const labelStyle = 'hyphens-auto';

const NavigationSidebar = () => {
  const { pathname } = useLocation();

  const isMissions = pathname.includes('mission');
  const isMissionLogs = pathname.includes('logs');
  const isInventory = pathname.includes('inventory');
  const isGemShop = pathname.includes('gemshop');
  const isLeaderboard = pathname.includes('leaderboard');
  // const isRaffle = pathname.includes('raffle');

  return (
    <div className="flex flex-row sm:flex-col justify-evenly sm:space-y-2 pt-2 pb-2 sm:p-6 bg-mainBg sm:bg-transparent bg-opacity-50 sm:self-end gap-2 sm:w-min text-center">
      <Link
        to="/missions/available"
        className={`
            ${linkStyle}
            ${isMissions ? 'text-delicateYellow' : 'text-white'}
        `}
      >
        <div
          className={`
            ${isMissions ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-inheritColorSmall'}
            ${svgIconStyle}
          `}
        >
          <MissionsIconSvg isActive={isMissions}/>
        </div>
        <div className={labelStyle}>
          Missions
        </div>
      </Link>
      <Link
        to="/logs?sortBy=startTime.descending"
        className={`
            ${linkStyle}
            ${isMissionLogs ? 'text-delicateYellow' : 'text-white'}
        `}
      >
        <div
          className={`
            ${isMissionLogs ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-inheritColorSmall'}
            ${svgIconStyle}
          `}
        >
          <MissionLogsSvg isActive={isMissionLogs}/>
        </div>
        <div className={labelStyle}>
          Logs
        </div>
      </Link>
      <Link
        to="/inventory/team?sortBy=gearScore.descending"
        className={`
            ${linkStyle}
            ${isInventory ? 'text-delicateYellow' : 'text-white'}
        `}
      >
        <div
          className={`
            ${isInventory ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-inheritColorSmall'}
            ${svgIconStyle}
          `}
        >
          <InventoryIconSvg isActive={isInventory} />
        </div>
        <span>Armory</span>
      </Link>
      <Link
        to="/gemstore"
        className={`
            ${linkStyle}
            ${isGemShop ? 'text-delicateYellow' : 'text-white'}
        `}
      >
        <div className={`
            ${isGemShop ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-navigationSidebarButton'}
            ${svgIconStyle}
          `}
        >
          <GemShopIconSvg />
        </div>
        <div className={labelStyle}>
          Gem Store
        </div>
      </Link>
      {/* DISABLED ON TESTNET */}
      {/* <Link */}
      {/*  to="/raffle" */}
      {/*  className={` */}
      {/*      ${linkStyle} */}
      {/*      ${isRaffle ? 'text-delicateYellow' : 'text-white'} */}
      {/*  `} */}
      {/* > */}
      {/*  <div className={` */}
      {/*      ${isRaffle ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-navigationSidebarButton'} */}
      {/*      ${svgIconStyle} */}
      {/*    `} */}
      {/*  > */}
      {/*    <RaffleIconSvg isActive={isRaffle} /> */}
      {/*  </div> */}
      {/*  <div className={labelStyle}> */}
      {/*    Raffle */}
      {/*  </div> */}
      {/* </Link> */}
      <Link
        to="/leaderboard"
        className={`
            ${linkStyle}
            ${isLeaderboard ? 'text-delicateYellow' : 'text-white'}
        `}
      >
        <div className={`
            ${isLeaderboard ? 'drop-shadow-navigationSidebarButton' : 'group-hover:drop-shadow-navigationSidebarButton'}
            ${svgIconStyle}
          `}
        >
          <LeaderboardIconSvg isActive={isLeaderboard} />
        </div>
        <div className={labelStyle}>
          Leaderboard
        </div>
      </Link>
    </div>
  );
};

export default NavigationSidebar;

import { MISSION_LABEL, MISSION_TYPE } from '../../consts';

export const MISSION_LOGS_FILTER_BY = [
  {
    label: 'Mission type',
    searchParam: 'missionType',
    values: [
      ...Object.entries(MISSION_TYPE).map(([key, value]) => (
        {
          label: MISSION_LABEL[value],
          searchParam: key.toLowerCase(),
        }
      )),
    ],
  },
];

export const MISSION_LOGS_FILTER_BY_MAP = {
  missionType: 'questType',
};

export const MISSION_LOGS_SORT_BY = [
  {
    label: 'Start date',
    searchParam: 'startTime',
  },
];

export const MISSION_LOGS_SORT_BY_MAP = {
  startTime: 'startTimeInSeconds',
};

import { NFT_COLLECTIONS_SEASON_1 } from '../../consts';

export const SHOP_FILTER_BY = [
  // {
  //   label: 'Type',
  //   searchParam: 'type',
  //   values: [
  //     {
  //       label: 'NFTs',
  //       searchParam: 'nfts',
  //     },
  //     {
  //       label: 'Collectibles',
  //       searchParam: 'collectable',
  //     },
  //   ],
  // },
  {
    label: 'Collection',
    searchParam: 'collection',
    // displayCondition: {
    //   key: 'type',
    //   value: 'nfts',
    // },
    values: NFT_COLLECTIONS_SEASON_1.map((collectionName) => ({
      label: collectionName,
      searchParam: collectionName.toLowerCase(),
    })),
  },
];

export const SHOP_FILTER_BY_MAP = {
  collection: 'ItemCollectionName',
};

import React from 'react';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { ShopState } from './shopSlice';
import {
  ShopItem,
  GetShopItemsAPIResponse, BuyItemAPIResponse, ExtraReducerGenericArg,
} from '../../../utils/additionalTypes';
import { RootState } from '../../store';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';
import { ITEM_TYPES, MESSAGE_VALUES } from '../../../utils/consts';
import { accountSlice } from '../account/accountSlice';
import { inventoryExtraReducers } from '../inventory/inventoryReducers';
import LoaderModal from '../../../components/modals/LoaderModal';
import ItemPurchaseSuccessfulModal from '../../../components/modals/ItemPurchaseSuccessfulModal';
import endpointUrl from '../../../utils/endpointUrl';
import debugLoggerService from '../../../utils/DebugLoggerService';
import defaultShopItemData from '../../../utils/defaultShopItemData';

const parseShopItems = (shopItems: ShopItem[], defaultShopItems: ShopItem[]) => defaultShopItems.map((defaultShopItem) => {
  const newItem = { ...defaultShopItem };

  if (newItem.ItemCollectionName?.includes('Berautistics') && newItem.ItemName.includes('#674')) {
    newItem.IsMainShopPrize = true;
  }

  if (newItem.ItemType === 'BeramoniumNFT') {
    newItem.ItemType = ITEM_TYPES.NFT;
  }

  const shopItemEquivalent = shopItems.find((shopItem) => newItem.ItemName === shopItem.ItemName);

  if (shopItemEquivalent) {
    newItem.isSold = false;
    newItem.ItemID = shopItemEquivalent.ItemID;
  } else {
    newItem.isSold = true;
  }

  return newItem;
});

// eslint-disable-next-line import/prefer-default-export
export const shopExtraReducers: ExtraReducers = {
  getShopItems: createExtraReducer<ShopState, Array<ShopItem>, ExtraReducerGenericArg>({
    reducer: {
      name: 'shop/getShopItems',
      callbackFn: async (extraReducerGenericArg, { dispatch, rejectWithValue }) => {
        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/shop`, {
            method: 'GET',
          }) as GetShopItemsAPIResponse;

          const { ShopItems } = res;

          if (ShopItems) { // TODO better validation
            return parseShopItems(ShopItems, defaultShopItemData);
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => ({
      isFetchingData: false,
      shopItems: payload,
    }),
    pendingCallback: (state) => {
      state.isFetchingData = true;
    },
    rejectedCallback: (state, { payload }) => {
      state.isFetchingData = false;

      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),
  buyItem: createExtraReducer<ShopState, ShopItem, ShopItem>({
    reducer: {
      name: 'shop/buyItem',
      callbackFn: async (item, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/shop/buy`, {
            method: 'POST',
            body: JSON.stringify({
              Quantity: 1,
              walletAddress,
              ItemID: item.ItemID,
            }),
          }) as BuyItemAPIResponse;

          const { message, gems: currentGems } = res;

          if (currentGems && message === MESSAGE_VALUES.success) { // TODO better validation
            dispatch(accountSlice.actions.setOwnedGems(currentGems)); // TODO add gems!
            dispatch(inventoryExtraReducers.getOwnedItems.reducer(null));

            if (!item.Unlimited) { // re-fetch shop to remove bought item
              dispatch(shopExtraReducers.getShopItems.reducer(null));
            }

            debugLoggerService.incrementTotalGemsSpent(item.ItemPrice);

            return item;
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (_, { payload }) => {
      modalService.pushModal(<ItemPurchaseSuccessfulModal item={payload} />, {
        wrapperStyle: 'gold', // TODO move this to modal definition itself instead of pushModal arg
      });
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
};

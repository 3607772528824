import { NFT_COLLECTIONS_SEASON_1 } from '../../consts';

export const INVENTORY_FILTER_BY = [
  {
    label: 'Type',
    searchParam: 'type',
    values: [
      {
        label: 'Equipment',
        searchParam: 'equipment',
      },
      {
        label: 'Crafting',
        searchParam: 'crafting',
      },
      {
        label: 'Collectibles',
        searchParam: 'collectibles',
      },
      {
        label: 'NFTs',
        searchParam: 'nfts',
      },
    ],
  },
  {
    label: 'Class',
    searchParam: 'class',
    displayCondition: {
      key: 'type',
      value: 'equipment',
    },
    values: [
      {
        label: 'Hunter',
        searchParam: 'hunter',
      },
      {
        label: 'Mage',
        searchParam: 'mage',
      },
      {
        label: 'Warrior',
        searchParam: 'warrior',
      },
    ],
  },
  {
    label: 'Collection',
    searchParam: 'collection',
    displayCondition: {
      key: 'type',
      value: 'nfts',
    },
    values: NFT_COLLECTIONS_SEASON_1.map((collectionName) => ({
      label: collectionName,
      searchParam: collectionName.toLowerCase(),
    })),
  },
];

export const INVENTORY_FILTER_BY_MAP = {
  type: 'ItemType',
  class: 'ItemClass',
  collection: 'ItemCollectionName',
};

import { SEARCH_PARAMS } from '../consts';

export default ( // TODO refactor & simplify
  searchParamType: ValueOf<typeof SEARCH_PARAMS>,
  currentSearchParams: URLSearchParams,
  newSearchParamKey: string,
  newSearchParamValue?: string | null,
): URLSearchParams => {
  const currentFilter = currentSearchParams.get(SEARCH_PARAMS.filter);
  const currentSortBy = currentSearchParams.get(SEARCH_PARAMS.sortBy);

  const newSearchParams = new URLSearchParams();

  if (searchParamType === SEARCH_PARAMS.filter) {
    if (currentSortBy) {
      newSearchParams.set(SEARCH_PARAMS.sortBy, currentSortBy);
    }

    const splitFilterValuesArray = currentFilter?.split(',') ?? [];
    const indexOfNewFilterKey = splitFilterValuesArray.findIndex((key) => key.split('.')[0] === newSearchParamKey);

    if (indexOfNewFilterKey !== -1) {
      if (newSearchParamValue) {
        splitFilterValuesArray.splice(indexOfNewFilterKey, 1, `${newSearchParamKey}.${newSearchParamValue}`);
      } else {
        splitFilterValuesArray.splice(indexOfNewFilterKey, 1);
      }
    } else if (newSearchParamValue) {
      splitFilterValuesArray.push(`${newSearchParamKey}.${newSearchParamValue}`);
    }

    if (splitFilterValuesArray.length) {
      newSearchParams.set(
        SEARCH_PARAMS.filter,
        splitFilterValuesArray.join(','),
      );
    }

    return newSearchParams;
  }

  if (searchParamType === SEARCH_PARAMS.sortBy) {
    if (currentFilter) {
      newSearchParams.set(SEARCH_PARAMS.filter, currentFilter);
    }

    const splitSortByValuesArray = currentSortBy?.split(',') ?? [];
    const indexOfNewSortByKey = splitSortByValuesArray.findIndex((key) => key.split('.')[0] === newSearchParamKey);

    if (indexOfNewSortByKey !== -1) {
      if (newSearchParamValue) {
        splitSortByValuesArray.splice(indexOfNewSortByKey, 1, `${newSearchParamKey}.${newSearchParamValue}`);
      } else {
        splitSortByValuesArray.splice(indexOfNewSortByKey, 1);
      }
    } else {
      splitSortByValuesArray.push(`${newSearchParamKey}.${newSearchParamValue}`);
    }

    if (splitSortByValuesArray.length) {
      newSearchParams.set(
        SEARCH_PARAMS.sortBy,
        splitSortByValuesArray.join(','),
      );
    }

    return newSearchParams;
  }

  return currentSearchParams;
};

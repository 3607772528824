import { EQUIPMENT_ATTRIBUTES } from '../../consts';

export const ARMORY_EQUIPMENT_FILTER_BY = [
  {
    label: 'Item type',
    searchParam: 'itemType',
    values: Object.values(EQUIPMENT_ATTRIBUTES).map(((equipmentAttribute) => (
      {
        label: equipmentAttribute,
        searchParam: equipmentAttribute.toLowerCase(),
      }
    ))),
  },
];

export const ARMORY_EQUIPMENT_FILTER_BY_MAP = {
  itemType: 'attributes.ItemGearType',
};

export const ARMORY_EQUIPMENT_SORT_BY = [
  {
    label: 'Rarity',
    searchParam: 'rarity',
  },
];

export const ARMORY_EQUIPMENT_SORT_BY_MAP = {
  rarity: 'gearScore',
};

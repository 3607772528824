import React, { useEffect } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import {
  berachainTestnetbArtio, Chain, mainnet, sepolia,
} from 'wagmi/chains';
import modalService from '../modalService/ModalService';
import IncorrectNetworkModal from './modals/IncorrectNetworkModal';

const SYMBOLS: Record<string, string> = {
  [mainnet.id]: 'Ξ',
  [sepolia.id]: 'Ξ🐬',
  [berachainTestnetbArtio.id]: '🐻⛓',
};

export default ({ desiredChain }: { desiredChain: Chain }) => {
  const { chain, isConnected } = useAccount();
  const { switchChain } = useSwitchChain();

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    const isDesiredChainSelected = chain?.id === desiredChain.id;

    if (!isDesiredChainSelected) {
      modalService.pushModal(
        <IncorrectNetworkModal
          network={{ name: desiredChain.name, symbol: SYMBOLS[desiredChain.id] }}
          switchNetworkCallback={() => switchChain({ chainId: desiredChain.id })}
        />,
        {
          canClose: false,
          shouldDisplayCloseButton: false,
        },
      );
    } else {
      modalService.closeModal();
    }
  }, [isConnected, chain, switchChain]);

  return null;
};

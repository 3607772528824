import React, { useEffect } from 'react';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import { useAppSelector } from '../../../store/hooks';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import { InventoryItem } from '../../../utils/additionalTypes';
import InventoryGridItem from '../Inventory/InventoryGridItem';
import useTailwindBreakpoint from '../../../utils/useTailwindBreakpoint';
import { BERA_CLASS } from '../../../utils/consts';
import { BeraNewAttributes } from './useBeraAttributes';
import filterArrayBySearchParamsReworked from '../../../utils/sortAndFilters/filterArrayBySearchParams';
import {
  ARMORY_EQUIPMENT_FILTER_BY_MAP,
  ARMORY_EQUIPMENT_SORT_BY_MAP,
} from '../../../utils/sortAndFilters/definitions/armoryEquipment';
import sortArrayBySearchParamsReworked from '../../../utils/sortAndFilters/sortArrayBySearchParams';

export default ({
  beraClass,
  withOverflowVisibleOnMobile,
  onInventoryItemHover,
  onInventoryItemClick,
  getBeraNewAttributes,
}: {
  beraClass: ValueOf<typeof BERA_CLASS>,
  withOverflowVisibleOnMobile?: boolean
  // eslint-disable-next-line no-unused-vars
  onInventoryItemHover: (arg: InventoryItem | null) => void
  // eslint-disable-next-line no-unused-vars
  onInventoryItemClick: (arg: InventoryItem) => void
  getBeraNewAttributes: () => BeraNewAttributes
}) => {
  const breakpoint = useTailwindBreakpoint();
  const shouldDisplayGridCutoffGradient = !breakpoint || ['xl', '2xl'].includes(breakpoint);
  const beraNewNewAttributes = getBeraNewAttributes();

  const ownedItems = useAppSelector((state) => state.inventory.ownedItems) as Array<InventoryItem>;

  useEffect(() => {
    if (!ownedItems) {
      modalService.pushModal(<ErrorModal error={new Error('empty inventory!!')} />); // TODO better error handling
    }
  }, [ownedItems]);

  if (!ownedItems) {
    return null;
  }

  const filteredOwnedItemsByClass = ownedItems.filter((ownedItem) => ownedItem.ItemClass === beraClass);
  const filteredOwnedItemsNotWorn = filteredOwnedItemsByClass.filter((ownedItem) => !Object.values(beraNewNewAttributes).find((newAttribute) => newAttribute?.ItemID === ownedItem.ItemID));

  const filteredOwnedItems = filterArrayBySearchParamsReworked<Array<InventoryItem>>(filteredOwnedItemsNotWorn, ARMORY_EQUIPMENT_FILTER_BY_MAP);
  const sortedItemsToDisplay = sortArrayBySearchParamsReworked<Array<InventoryItem>>(filteredOwnedItems, ARMORY_EQUIPMENT_SORT_BY_MAP);

  return <GenericItemGrid
    extraClassname={`
      mt-2 mb-4 px-4 lg:px-8
      ${withOverflowVisibleOnMobile && 'overflow-visible lg:overflow-auto'}
    `}
    minItemSizeInRem={5}
    itemsToDisplay={sortedItemsToDisplay.map((ownedItem) => (
      // eslint-disable-next-line react/jsx-key
      <div
        className="cursor-pointer"
        onMouseEnter={() => onInventoryItemHover(ownedItem)}
        onMouseLeave={() => onInventoryItemHover(null)}
        onClick={() => onInventoryItemClick(ownedItem)}
      >
        <InventoryGridItem item={ownedItem}/>
      </div>
    ))}
    withGradientCutoff={withOverflowVisibleOnMobile && shouldDisplayGridCutoffGradient}
  />;
};

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  FilterList, FilterValue,
} from './GenericSearchParams';
import getUpdatedSearchParams from '../../utils/sortAndFilters/getUpdatedSearchParams';
import getSearchParamsValue from '../../utils/sortAndFilters/definitions/getSearchParamsValue';
import { SEARCH_PARAMS } from '../../utils/consts';
import useTailwindBreakpoint from '../../utils/useTailwindBreakpoint';

const getValueLabelBySearchParam = (values: Array<FilterValue>, searchParam?: string) => {
  if (!searchParam) {
    return 'All';
  }

  return values.find(({ searchParam: valueSearchParam }) => valueSearchParam === searchParam)?.label;
};

const getUpdatedConditionalSearchParams = (currentSearchParams: URLSearchParams, filtersToDisplay: FilterList) => {
  let newSearchParams = currentSearchParams;

  filtersToDisplay.filter(({ displayCondition }) => displayCondition).forEach(({
    searchParam,
    displayCondition,
  }) => {
    if (displayCondition) {
      const shouldDisplayConditionalFilter = getSearchParamsValue(SEARCH_PARAMS.filter, currentSearchParams, displayCondition.key) === displayCondition.value;

      if (!shouldDisplayConditionalFilter) {
        newSearchParams = getUpdatedSearchParams(SEARCH_PARAMS.filter, currentSearchParams, searchParam);
      }
    }
  });

  return newSearchParams;
};

export default ({
  filtersToDisplay,
}: {
  filtersToDisplay: FilterList
}) => {
  const breakpoint = useTailwindBreakpoint();
  const shouldCloseDropdownOnClick = breakpoint && ['sm', 'md'].includes(breakpoint);

  const [searchParams, setSearchParams] = useSearchParams();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  return (
    <div className="flex flex-row flex-wrap">
      {filtersToDisplay.map(({
        label: filterLabel,
        searchParam: filterSearchParam,
        values,
        displayCondition,
      }) => {
        if (displayCondition) {
          if (getSearchParamsValue(SEARCH_PARAMS.filter, searchParams, displayCondition.key) !== displayCondition.value) {
            return null;
          }
        }

        const currentSearchParamValue = getSearchParamsValue(SEARCH_PARAMS.filter, searchParams, filterSearchParam);
        const labelByCurrentSearchParamValue = getValueLabelBySearchParam(values, currentSearchParamValue);

        return (
          <div
            key={filterSearchParam}
            className={`${displayCondition && 'animate-filterElementFadeIn'} flex flex-col items-start px-2 select-none cursor-pointer`}
            onMouseEnter={() => setOpenDropdown(filterSearchParam)}
            onMouseLeave={() => setOpenDropdown(null)}
          >
            <div className="absolute w-full h-4 -bottom-3"/>
            <div className="text-sm">{filterLabel}</div>
            <div className="relative">
              <button className="md:text-lg font-bold text-left whitespace-nowrap">
                {labelByCurrentSearchParamValue}
              </button>
              {openDropdown === filterSearchParam ? (
                <div className="z-10 absolute top-6 md:top-7 -left-[calc(1rem+1px)] flex flex-col animate-filterDropdownFadeIn">
                  <div className="flex flex-row w-full h-2 pointer-events-none">
                    <div className="bg-mainBg border-t-[1px] border-l-[1px] border-white rounded-tl w-2 min-w-[0.5rem]"/>
                    <div className="md:text-lg font-bold px-2 text-transparent border-[1px] border-t-transparent border-white rounded-br rounded-bl whitespace-nowrap">
                      {labelByCurrentSearchParamValue}
                    </div>
                    <div className="bg-mainBg border-t-[1px] border-r-[1px] border-white rounded-tr w-full min-w-[0.5rem]"/>
                  </div>
                  <div className="py-2 bg-mainBg border-white border-[1px] border-t-0 rounded-bl rounded-br">
                    <div
                      className={`flex flex-row px-4 ${currentSearchParamValue ?
                        'cursor-pointer hover:bg-mainBgLite' : 'cursor-default bg-mainBgLite'
                      }`}
                      {...(currentSearchParamValue && {
                        onClick: () => {
                          setSearchParams(getUpdatedConditionalSearchParams(getUpdatedSearchParams(SEARCH_PARAMS.filter, searchParams, filterSearchParam), filtersToDisplay));
                          if (shouldCloseDropdownOnClick) {
                            setOpenDropdown(null);
                          }
                        },
                      })}
                    >
                      All
                    </div>
                    {values.map(({ label: valueLabel, searchParam: valueSearchParam }) => (
                      <div
                        key={valueSearchParam}
                        className={`flex flex-row px-4 whitespace-nowrap ${currentSearchParamValue === valueSearchParam ?
                          'cursor-default bg-mainBgLite' : 'cursor-pointer hover:bg-mainBgLite'
                        }`}
                        onClick={() => {
                          setSearchParams(getUpdatedConditionalSearchParams(getUpdatedSearchParams(SEARCH_PARAMS.filter, searchParams, filterSearchParam, valueSearchParam), filtersToDisplay));
                          if (shouldCloseDropdownOnClick) {
                            setOpenDropdown(null);
                          }
                        }}
                      >
                        {valueLabel}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      })
    }
    </div>
  );
};

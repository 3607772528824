import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BerasToDisplay, Bera,
} from '../../../utils/additionalTypes';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import BeraCardBig from '../MissionBriefing/BeraCardBig';
import EmptyBerasOpenseaTip from '../MissionBriefing/EmptyBerasOpenseaTip';
import { useAppSelector } from '../../../store/hooks';
import GenericSearchParams from '../../GenericSearchParams/GenericSearchParams';
import filterArrayBySearchParamsReworked from '../../../utils/sortAndFilters/filterArrayBySearchParams';
import {
  CURRENT_TEAM_FILTER_BY, CURRENT_TEAM_FILTER_BY_MAP, CURRENT_TEAM_SORT_BY, CURRENT_TEAM_SORT_BY_MAP,
} from '../../../utils/sortAndFilters/definitions/currentTeam';
import sortArrayBySearchParamsReworked from '../../../utils/sortAndFilters/sortArrayBySearchParams';

export default () => {
  const navigate = useNavigate();

  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras);
  const berasInQuest = useAppSelector((state) => state.beras.berasInQuest);

  if (!ownedBeras || !berasInQuest) {
    return null;
  }

  const berasToDisplay: BerasToDisplay = ownedBeras.reduce((acc, bera) => [
    ...acc,
    {
      ...bera,
      inMission: berasInQuest.includes(bera.id),
    },
  ], [] as BerasToDisplay);

  const filteredBerasToSort = filterArrayBySearchParamsReworked<BerasToDisplay>(berasToDisplay, CURRENT_TEAM_FILTER_BY_MAP);

  const sortedBerasToSort = sortArrayBySearchParamsReworked<BerasToDisplay>(filteredBerasToSort, CURRENT_TEAM_SORT_BY_MAP);

  const availableBerasFirstToDisplay = sortedBerasToSort.sort((a, b) => {
    if (a.inMission && !b.inMission) {
      return 1;
    }
    if (!a.inMission && b.inMission) {
      return -1;
    }
    return 0;
  });

  const shouldDisplayEmptyBerasInfo = !availableBerasFirstToDisplay.length;

  return (
    <>
      <GenericSearchParams
        filtersToDisplay={CURRENT_TEAM_FILTER_BY}
        sortByToDisplay={CURRENT_TEAM_SORT_BY}
      />
      {shouldDisplayEmptyBerasInfo ? (
        <EmptyBerasOpenseaTip/>
      ) : (
        <GenericItemGrid
          extraClassname="mt-2 lg:mb-8 px-4 lg:px-8"
          aspectRatio={'1/1.5'}
          minItemSizeInRem={12} // TODO add minItemSize for different screen widths
          withGradientCutoff
          itemsToDisplay={availableBerasFirstToDisplay.map((bera) => {
            const isUnique = bera.attributes.Spec === 'Unique';
            const isInMission = bera.inMission;

            return (
              <BeraCardBig
                key={bera.id}
                bera={{
                  ...bera,
                  isUnique,
                }}
                {...(!isUnique && !isInMission && {
                  additionalProps: {
                    onClick: () => navigate(`/armory/${bera.id}/?sortBy=rarity.descending`),
                  },
                })}
              />
            );
          })}
        />)}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import { useAppSelector } from '../../../store/hooks';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import { InventoryItem } from '../../../utils/additionalTypes';
import InventoryGridItem from './InventoryGridItem';
import DisenchantmentContainer from './DisenchantmentContainer';
import { ITEM_TYPES, SEARCH_PARAMS } from '../../../utils/consts';
import { tabs } from './InventoryContainer';
import filterArrayBySearchParamsReworked from '../../../utils/sortAndFilters/filterArrayBySearchParams';
import { INVENTORY_FILTER_BY_MAP } from '../../../utils/sortAndFilters/definitions/inventoryItems';
import { DISENCHANTMENT_FILTER_BY_MAP } from '../../../utils/sortAndFilters/definitions/disenchantmentItems';

export default () => {
  const [...ownedItems] = useAppSelector((state) => state.inventory.ownedItems) as Array<InventoryItem>;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const filterSearchParamsValue = searchParams.get(SEARCH_PARAMS.filter);
  const isDisenchantmentActive = pathname.includes(tabs.disenchanting);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

  useEffect(() => {
    if (!ownedItems) {
      modalService.pushModal(<ErrorModal error={new Error('empty inventory!!')} />); // TODO better error handling
    }
  }, [ownedItems]);

  useEffect(() => {
    if (!isDisenchantmentActive) {
      setSelectedItems([]);
    }
  }, [isDisenchantmentActive]);

  useEffect(() => {
    setSelectedItems([]);
  }, [filterSearchParamsValue]);

  if (!ownedItems) {
    return null;
  }

  const filteredOwnedItemsToSort = filterArrayBySearchParamsReworked<Array<InventoryItem>>(
    ownedItems,
    (isDisenchantmentActive ? DISENCHANTMENT_FILTER_BY_MAP : INVENTORY_FILTER_BY_MAP) as Record<string, string>,
  );

  let itemsToDisplay = filteredOwnedItemsToSort.sort((a, b) => (b?.gearScore ?? 0) - (a?.gearScore ?? 0));

  if (isDisenchantmentActive) {
    itemsToDisplay = itemsToDisplay.filter(({ ItemType }) => ItemType === ITEM_TYPES.Equipment);
  }

  return <GenericItemGrid
    extraClassname="mt-2 mb-4 px-4 lg:px-8"
    {...(isDisenchantmentActive && {
      extraStickyElement: <DisenchantmentContainer
        selectedItems={selectedItems}
        allSelected={selectedItems.length === itemsToDisplay.length}
        onSelectAll={() => {
          setSelectedItems(itemsToDisplay.map((ownedItem) => ownedItem.ItemID));
        }}
        onDeselectAll={() => {
          setSelectedItems([]);
        }}
        hasItemsToDisplay={itemsToDisplay.length !== 0}
      />,
    })}
    itemsToDisplay={itemsToDisplay.map((ownedItem) => (
      // eslint-disable-next-line react/jsx-key
      <InventoryGridItem
        item={ownedItem}
        {...(isDisenchantmentActive && {
          isSelected: selectedItems.includes(ownedItem.ItemID),
          onClick: () => {
            const selectedItemIndex = selectedItems.indexOf(ownedItem.ItemID);

            if (selectedItemIndex === -1) {
              setSelectedItems([
                ...selectedItems,
                ownedItem.ItemID,
              ]);
            } else {
              selectedItems.splice(selectedItemIndex, 1);
              setSelectedItems([...selectedItems]);
            }
          },
        })}
      />
    ))}
  />;
};

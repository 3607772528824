import { GEAR_RARITY, GEAR_RARITY_TYPES } from '../../consts';

export const DISENCHANTMENT_FILTER_BY = [
  {
    label: 'Rarity',
    searchParam: 'rarity',
    values: [
      {
        label: GEAR_RARITY_TYPES.Common,
        searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Common].toString(),
      },
      {
        label: GEAR_RARITY_TYPES.Rare,
        searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Rare].toString(),
      },
      {
        label: GEAR_RARITY_TYPES.Epic,
        searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Epic].toString(),
      },
      {
        label: GEAR_RARITY_TYPES.Legendary,
        searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Legendary].toString(),
      },
    ],
  },
  {
    label: 'Class',
    searchParam: 'class',
    values: [
      {
        label: 'Hunter',
        searchParam: 'hunter',
      },
      {
        label: 'Mage',
        searchParam: 'mage',
      },
      {
        label: 'Warrior',
        searchParam: 'warrior',
      },
    ],
  },
];

export const DISENCHANTMENT_FILTER_BY_MAP = {
  rarity: 'gearScore',
  class: 'ItemClass',
};

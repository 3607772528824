import React from 'react';
import {
  CompleteQuestAPIResponse, InventoryItem,
  PurchaseQuestAPIResponse,
  Mission,
  MissionOutcome,
  MissionAPIResponse,
  RetireFromQuestAPIResponse,
  StartQuestAPIResponse,
  MissionsTimers as MissionsTimersType, LoggedMission, ExtraReducerGenericArg,
} from '../../../utils/additionalTypes';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { MissionsState } from './missionsSlice';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';
import { RootState } from '../../store';
import { MESSAGE_VALUES, MISSION_STATUS, MISSION_TYPE } from '../../../utils/consts';
import { accountSlice } from '../account/accountSlice';
import { berasSlice } from '../beras/berasSlice';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import LoaderModal from '../../../components/modals/LoaderModal';
import MissionStartModal from '../../../components/modals/MissionStartModal';
import router from '../../../router/router';
import MissionResultModal from '../../../components/modals/MissionResultModal';
import SquadRetiredModal from '../../../components/modals/SquadRetiredModal';
import { inventoryExtraReducers } from '../inventory/inventoryReducers';
import endpointUrl from '../../../utils/endpointUrl';
import debugLoggerService from '../../../utils/DebugLoggerService';
import { leaderboardExtraReducers } from '../leaderboard/leaderboardReducers';
import { accountExtraReducers } from '../account/accountReducers';

type ParsedMissionsState = {
  availableMissions: Mission[]
  activeMissions: Mission[]
}

type HasLeveledUp = {
  currentLevel: number
  previousLevel: number
}

const evalBerasInQuest = (missions: Array<Mission>): Array<string> => missions
  .filter(({ status }) => status === MISSION_STATUS.active)
  .reduce((acc, mission) => {
    if (mission.beraIds) {
      return [
        ...acc,
        ...mission.beraIds,
      ];
    }

    return acc;
  }, [] as string[]);

const parseMissionsToFitStoreModel = (missions: Array<Mission>): ParsedMissionsState => ({
  availableMissions: missions.filter(({ status }) => status === MISSION_STATUS.inactive),
  activeMissions: missions.filter(({ status }) => status === MISSION_STATUS.active),
});

// eslint-disable-next-line import/prefer-default-export
export const missionsExtraReducers: ExtraReducers = {
  getMissions: createExtraReducer<MissionsState, ParsedMissionsState & MissionsTimersType, ExtraReducerGenericArg>({
    reducer: {
      name: 'missions/getMissions',
      callbackFn: async (extraReducerGenericArg, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests`, {
            method: 'POST',
            body: JSON.stringify({ walletAddress }),
          }) as MissionAPIResponse;

          const {
            Missions, MissionsTimers, gems,
          } = res;

          if (Missions != null && gems != null) {
            dispatch(accountSlice.actions.setOwnedGems(gems));
            dispatch(berasSlice.actions.setBerasInQuest(evalBerasInQuest(Missions)));

            return {
              ...parseMissionsToFitStoreModel(Missions),
              missionsTimers: MissionsTimers,
            };
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    rejectedCallback: (_, { payload }) => {
      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),
  startMission: createExtraReducer<MissionsState, ParsedMissionsState & MissionsTimersType, {
    missionId: number,
    beraIds: Array<number>
  }>({
    reducer: {
      name: 'missions/startMission',
      callbackFn: async ({ missionId, beraIds }, { getState, dispatch, rejectWithValue }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/start`, {
            method: 'POST',
            body: JSON.stringify({ beraIds, questId: missionId, walletAddress }),
          }) as StartQuestAPIResponse;

          const { missions, MissionsTimers } = res;

          if (missions) {
            dispatch(berasSlice.actions.setBerasInQuest(evalBerasInQuest(missions)));

            return {
              ...parseMissionsToFitStoreModel(missions),
              missionsTimers: MissionsTimers,
            };
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (state, { payload }) => {
      modalService.pushModal(<MissionStartModal />);

      router.navigate('/missions/started'); // this a bit janky?

      return ({
        ...state,
        ...payload,
      });
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
  completeMission: createExtraReducer<MissionsState, {
    missions: ParsedMissionsState,
    outcome: MissionOutcome
    items: InventoryItem[]
    hasLeveledUp?: HasLeveledUp | false
  }, Mission>({
    reducer: {
      name: 'missions/completeMission',
      callbackFn: async (mission, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress, level: previousLevel },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/complete`, {
            method: 'POST',
            body: JSON.stringify({ walletAddress, questId: mission.id }),
          }) as CompleteQuestAPIResponse;

          const {
            missions, outcome, gems, items, level: currentLevel,
          } = res;

          if (missions && outcome) { // TODO better validation
            dispatch(accountSlice.actions.setOwnedGems(gems)); // TODO add gems!
            dispatch(berasSlice.actions.setBerasInQuest(evalBerasInQuest(missions)));
            dispatch(leaderboardExtraReducers.getLeaderboardEntries.reducer(null));
            dispatch(missionsExtraReducers.getMissionLogs.reducer(null));

            if (items) {
              dispatch(inventoryExtraReducers.getOwnedItems.reducer(null));
            }

            debugLoggerService.onMissionComplete(mission.type, outcome);

            if (currentLevel !== undefined && previousLevel !== null) {
              dispatch(accountExtraReducers.getAccountData.reducer(null));

              const hasLeveledUp = currentLevel > previousLevel ? {
                currentLevel,
                previousLevel,
              } : false;

              return {
                missions: parseMissionsToFitStoreModel(missions),
                outcome,
                items,
                hasLeveledUp,
              };
            }

            return {
              missions: parseMissionsToFitStoreModel(missions),
              outcome,
              items,
              hasLeveledUp: false,
            };
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (state, { payload }) => {
      modalService.pushModal(<MissionResultModal
        outcome={payload.outcome}
        items={payload.items}
        {...(payload.hasLeveledUp && {
          hasLeveledUp: payload.hasLeveledUp as HasLeveledUp,
        })}
      />, {
        wrapperStyle: 'purple', // TODO move this to modal definition itself instead of pushModal arg
      });

      return {
        ...state,
        ...payload.missions,
      };
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
  buyMission: createExtraReducer<MissionsState, ParsedMissionsState, {
    missionType: typeof MISSION_TYPE,
    cost: number
  }>({
    reducer: {
      name: 'missions/buyMission',
      callbackFn: async ({
        cost,
        missionType,
      }, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/purchase`, {
            method: 'POST',
            body: JSON.stringify({ questType: missionType, walletAddress }),
          }) as PurchaseQuestAPIResponse;

          const {
            Missions, message, gems: currentGems,
          } = res;

          if (Missions && message === MESSAGE_VALUES.success) { // TODO better validation
            dispatch(accountSlice.actions.setOwnedGems(currentGems)); // TODO add gems!

            debugLoggerService.incrementTotalGemsSpent(cost);

            return parseMissionsToFitStoreModel(Missions);
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (state, { payload }) => {
      modalService.closeModal(); // TODO showcase newly bought mission, allow for redirect to it?
      return {
        ...state,
        ...payload,
      };
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
  refreshMission: createExtraReducer<MissionsState, ParsedMissionsState, {
    missionId: string,
    cost: number
  }>({
    reducer: {
      name: 'missions/refreshMission',
      callbackFn: async ({
        cost,
        missionId,
      }, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/refresh`, {
            method: 'POST',
            body: JSON.stringify({ questId: missionId, walletAddress }),
          }) as PurchaseQuestAPIResponse;

          const {
            Missions, message, gems: currentGems,
          } = res;

          if (Missions && message === MESSAGE_VALUES.success) { // TODO better validation
            dispatch(accountSlice.actions.setOwnedGems(currentGems)); // TODO add gems!

            debugLoggerService.incrementTotalGemsSpent(cost);

            return parseMissionsToFitStoreModel(Missions);
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (state, { payload }) => {
      modalService.closeModal(); // TODO showcase newly bought mission, allow for redirect to it?
      return {
        ...state,
        ...payload,
      };
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
  retireFromMission: createExtraReducer<MissionsState, ParsedMissionsState, string>({
    reducer: {
      name: 'missions/retireFromMission',
      callbackFn: async (missionId, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/retire`, {
            method: 'POST',
            body: JSON.stringify({ questId: missionId, walletAddress }),
          }) as RetireFromQuestAPIResponse;

          const { Missions, message } = res;

          if (Missions && message === MESSAGE_VALUES.success) {
            dispatch(berasSlice.actions.setBerasInQuest(evalBerasInQuest(Missions)));

            return parseMissionsToFitStoreModel(Missions);
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (state, { payload }) => {
      modalService.pushModal(<SquadRetiredModal />);

      return {
        ...state,
        ...payload,
      };
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
  getMissionLogs: createExtraReducer<MissionsState, Array<LoggedMission>, ExtraReducerGenericArg>({
    reducer: {
      name: 'missions/getMissionLogs',
      callbackFn: async (extraReducerGenericArg, { getState, rejectWithValue, dispatch }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/quests/logs`, {
            method: 'POST',
            body: JSON.stringify({ count: 50, walletAddress }),
          }) as {
            message: ValueOf<typeof MESSAGE_VALUES>;
            logs: Array<LoggedMission & {
              startedTime: { _seconds: number, _nanoseconds?: number }
              completeTime: { _seconds: number, _nanoseconds?: number }
            }>
          };

          const { message, logs } = res;

          if (logs && message === MESSAGE_VALUES.success) {
            return logs.map((log) => {
              const { startedTime, completeTime, ...restLog } = log;

              return {
                ...restLog,
                // eslint-disable-next-line no-underscore-dangle
                startTimeInSeconds: startedTime._seconds,
                // eslint-disable-next-line no-underscore-dangle
                endTimeInSeconds: completeTime._seconds,
              };
            });
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => ({
      ...state,
      missionLogs: payload,
    }),
    rejectedCallback: (_, { payload }) => {
      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),

};

import { TrackJS } from 'trackjs';
import { getWalletClient } from '@wagmi/core';
import wagmiConfig from './wagmiConfig';

const TrackJSService = () => ({
  installTrackJS: () => {
    if (process.env.REACT_APP_VERCEL_ENV !== 'development') {
      TrackJS.install({
        token: 'ad56296b08c7404792d979d562a2aaf8',
        application: 'production',
      });
    }
  },
  trackWithUserInfo: (label?: string, metadata?: any) => async (...error: any[]) => {
    try {
      const client = await getWalletClient(wagmiConfig);
      const { account, chain: { id } } = client;

      TrackJS.track({
        label,
        metadata,
        account,
        chainId: id,
        error,
      });
    } catch (e) {
      console.warn(e);

      TrackJS.track({
        label,
        metadata,
        message: 'getWalletConfig failed',
        error: e,
      });
    }
  },
});

const trackJSService = TrackJSService();

export default trackJSService;

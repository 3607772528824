import { berachainTestnetbArtio } from 'viem/chains';

const API_URL = process.env.REACT_APP_API_URL || 'https://beramonium-gemhunters-api-bartio-2wsvsugfrq-wl.a.run.app';
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || 'https://ddvhcudjyjvrljrjlyvu.supabase.co';

export const getEquipmentPreviewUrl = (itemId: string) => `${SUPABASE_URL}/storage/v1/object/public/armory/layers/${itemId}.png`;
export function getNakedBeraPreviewUrl({ chainId, beraId }: { chainId: number; beraId: string }) {
  return `${SUPABASE_URL}/storage/v1/object/public/armory/${chainId === berachainTestnetbArtio.id ? 'genesis-bartio' : 'genesis'}/${beraId}.png`;
}

export default API_URL;

import { BERA_SPECS } from '../../consts';

export const CURRENT_TEAM_FILTER_BY = [
  {
    label: 'Spec',
    searchParam: 'spec',
    values: [
      ...Object.entries(BERA_SPECS).map(([key, value]) => (
        {
          label: value,
          searchParam: key,
        }
      )),
    ],
  },
];

export const CURRENT_TEAM_SORT_BY = [
  {
    label: 'Gear score',
    searchParam: 'gearScore',
  },
];

export const CURRENT_TEAM_FILTER_BY_MAP = {
  collection: {
    mapTo: 'attributes.Tier',
    shouldDisplayCondition: (filterValue: string, elementValue: string) => { // TODO better typing?
      if (filterValue === 'genesis' && elementValue === 'genesis') {
        return true;
      }

      return filterValue === 'new' && (elementValue === 'naked' || elementValue === 'evolved');
    },
  },
  tier: 'attributes.Tier',
  spec: 'attributes.Spec',
};

export const CURRENT_TEAM_SORT_BY_MAP = {
  gearScore: 'gearScore',
};

import React from 'react';
import { useChainId } from 'wagmi';
import { BeraAttributes, InventoryItem } from '../../../utils/additionalTypes';
import { BeraNewAttributes, SetBeraNewAttributeType } from './useBeraAttributes';
import {
  BERA_ATTRIBUTES,
} from '../../../utils/consts';
import InventorySlots from './EquipmentSlots';
import EquipmentPreview from './EquipmentPreview';
import { getNakedBeraPreviewUrl } from '../../../utils/endpointUrl';
import BakeAndResetButtonRow from './BakeAndResetButtonRow';
import BeraTitleWithAdditionalInfo from './BeraTitleWithAdditionalInfo';

export default ({
  getBeraOriginalAttributes,
  getBeraNewAttributes,
  getBeraId,
  currentlyHoveredItem,
  setBeraNewAttribute,
  resetBeraNewAttributes,
  equipNewAttributes,
}: {
    getBeraOriginalAttributes: () => BeraAttributes
    getBeraNewAttributes: () => BeraNewAttributes
    getBeraId: () => string
    currentlyHoveredItem: InventoryItem | null
    setBeraNewAttribute: SetBeraNewAttributeType
    resetBeraNewAttributes: () => void
    equipNewAttributes: () => void
}) => {
  const chainId = useChainId();

  const originalAttributes = getBeraOriginalAttributes();
  const newAttributes = getBeraNewAttributes();

  const beraBackground = originalAttributes[BERA_ATTRIBUTES.Background];
  const beraAura = originalAttributes[BERA_ATTRIBUTES.Aura];

  const shouldDisplayNothingToEquipModal = !Object.values(newAttributes).filter((attr) => attr).length;

  return (
    <>
      <div className="lg:hidden w-full flex flex-col items-center justify-center text-white py-4 px-2 gap-2">
        <BeraTitleWithAdditionalInfo
          originalAttributes={originalAttributes}
          newAttributes={newAttributes}
          beraId={getBeraId()}
        />
      </div>
      <div className="lg:basis-2/5 bg-mainBg lg:min-w-[21rem]">
        <div
          className="h-full relative flex flex-col justify-between items-center lg:rounded-tr-3xl lg:rounded-br-3xl"
          style={{
            backgroundImage: `url(/armory/bgs/${beraBackground.replace(' ', '').toLowerCase()}.png)`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className={`
              hidden w-full
              lg:flex flex-col items-center justify-center
              text-white pt-8 px-2
              drop-shadow-blackBackground
              z-10 gap-4
            `}
          >
            <BeraTitleWithAdditionalInfo
              originalAttributes={originalAttributes}
              newAttributes={newAttributes}
              beraId={getBeraId()}
            />
          </div>
          <div
            className="absolute top-0 right-0 bottom-0 left-0"
            style={{
              backgroundImage: `url(/armory/auras/${beraAura.replace(' ', '').toLowerCase()}.png)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div
            className="relative w-full min-h-[17rem] h-[30vh] lg:h-[35rem] bg-contain lg:bg-cover overflow-hidden"
            style={{
              backgroundImage: `url(${getNakedBeraPreviewUrl({ chainId, beraId: getBeraId() })})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <EquipmentPreview
              newAttributes={getBeraNewAttributes()}
              originalAttributes={getBeraOriginalAttributes()}
              currentlyHoveredItem={currentlyHoveredItem}
            />
            <InventorySlots
              getBeraNewAttributes={getBeraNewAttributes}
              setBeraNewAttribute={setBeraNewAttribute}
            />
          </div>
          <div className="hidden lg:flex bottom-0 right-0 left-0 w-full flex-row justify-around gap-2 pb-8 px-4 z-10">
            <BakeAndResetButtonRow
              shouldDisplayNothingToEquipModal={shouldDisplayNothingToEquipModal}
              resetBeraNewAttributes={resetBeraNewAttributes}
              equipNewAttributes={equipNewAttributes}
            />
          </div>
        </div>
      </div>
      <div className="lg:hidden flex w-full justify-around gap-2 p-4">
        <BakeAndResetButtonRow
          shouldDisplayNothingToEquipModal={shouldDisplayNothingToEquipModal}
          resetBeraNewAttributes={resetBeraNewAttributes}
          equipNewAttributes={equipNewAttributes}
        />
      </div>
    </>
  );
};

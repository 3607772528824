import React, { useEffect, useState } from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';
import confettiService from '../../utils/ConfettiService';

export default ({
  levelData,
}: {
  levelData: {
  currentLevel: number,
  previousLevel: number
}
}) => {
  const [shouldInitAnim, setShouldInitAnim] = useState(false);
  const [hasTransitionEnded, setHasTransitionEnded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldInitAnim(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (hasTransitionEnded) {
      confettiService.addConfetti({
        emojis: ['✨', '🌟', '⭐️'],
        emojiSize: 100,
        confettiNumber: 50,
      });
    }
  }, [hasTransitionEnded]);

  const distanceBetweenLevels = 10;

  const centerAlignment = 0.5;
  const levelsToDisplay = 10;
  const offsetFromEnd = 2;

  const distanceToTranslate = levelData.currentLevel >= levelsToDisplay ?
    centerAlignment + (levelsToDisplay - offsetFromEnd) * distanceBetweenLevels :
    centerAlignment + levelData.currentLevel * distanceBetweenLevels;

  return (
    <GenericModalLayout
      titleLabel="Level up!"
      titleRibbonStyle="gold"
      onConfirmLabel="Continue"
      onConfirm={() => modalService.closeModal()}
      onConfirmStyle="gold"
    >
      <div className="flex flex-col justify-center gap-2 mb-8">
        <div className="font-bold text-lg text-center">Congratulations brave Bera warrior!</div>
        <div className="text-center">Your aura is growing with each successful quest!</div>
      </div>
      <div
        className="flex flex-row h-12 w-[20rem] md:w-[30rem] bg-gradient-to-r from-mainBg via-mainBgSuperLite to-mainBg overflow-hidden"
        style={{
          maskImage: 'linear-gradient(90deg, transparent 0, rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), transparent 100%)',
        }}
      >
        <div
          className="flex flex-row relative w-full transition-transform ease-in-out items-end"
          style={{
            transitionDuration: '1s',
            transform: shouldInitAnim ? `translateX(-${distanceToTranslate}rem)` : 'translateX(0)',
          }}
          onTransitionEnd={() => setHasTransitionEnded(true)}
        >
          {Array(levelsToDisplay + 1).fill(null).map((_, i) => { // NOTE display one extra for currentLevel - levelsToDisplay === 1
            const levelToDisplay = levelData.currentLevel >= levelsToDisplay ? levelData.currentLevel - levelsToDisplay + i + offsetFromEnd : i;
            const isCurrentLevel = levelToDisplay === levelData.currentLevel;

            return (
              <div
                key={i}
                className="absolute flex flex-row"
                style={{
                  width: `${distanceBetweenLevels}rem`,
                  left: `calc(50% + ${i * distanceBetweenLevels}rem)`,
                }}
              >
                <div
                  className={`
                    flex flex-row justify-center h-12 w-4
                    ${isCurrentLevel ? 'text-lg drop-shadow-inheritColorExtraSmall' : 'text-xs font-bold opacity-50'} 
                  `}
                >
                  <div className={`
                    absolute 
                    flex justify-center items-center
                    transition-transform
                    ${isCurrentLevel ? 'self-center' : 'bottom-1'}
                    ${isCurrentLevel && hasTransitionEnded && 'scale-150'}
                  `}
                  >
                    {levelToDisplay}
                    {isCurrentLevel && (
                      <div className={`
                          rounded-full
                          absolute w-4 h-4 transition-all ease-out
                          ${hasTransitionEnded && 'border-white/70 border-solid border-[2px] opacity-0'}
                        `}
                        style={{
                          transitionDuration: '1s',
                          transform: hasTransitionEnded ? 'scale(15)' : 'scale(1)',
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between px-4 items-end">
                  {Array(5).fill(null).map((__, n) => (
                    <div
                      key={n}
                      className="w-[1px] h-3 bg-white/10"
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </GenericModalLayout>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useDisconnect } from 'wagmi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSlice } from '../../../store/slices/ui/uiSlice';
import { accountSlice } from '../../../store/slices/account/accountSlice';
import AccountLevelProgressBar from './AccountLevelProgressBar';
import TooltipTrigger from '../../TooltipTrigger';
import GenericButton from '../../GenericButton';
import getTrunacatedWalletAddress from '../../../utils/getTrunacatedWalletAddress';
import { HamburgerIconSvg } from '../../SvgIcons';

const NavigationBar = () => {
  const address = useAppSelector((state) => state.account.walletAddress);
  const gemsCount = useAppSelector((state) => state.account.ownedGems);
  const dispatch = useAppDispatch();
  const { disconnect } = useDisconnect({
    mutation: {
      onSuccess: () => {
        dispatch(accountSlice.actions.logOut());
      },
    },
  });

  const truncatedAddress = getTrunacatedWalletAddress(address as string);

  return (
    <div className="flex flex-col">
      <div
        className="flex justify-between items-center px-4 sm:px-6 py-2 bg-mainBg bg-opacity-50 text-white"
      >
        <Link to='/missions/available'>
          <div
            className="min-w-[12rem] h-[3rem] -translate-y-[3px]"
            style={{
              backgroundImage: 'url(\'/logo.png\')',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Link>
        <div className="w-full flex flex-row justify-end items-center gap-4">
          <TooltipTrigger
            content={
              <div>Complete missions to get more Gems!</div>
          }
            extraClassname="mr-4 sm:mr-0"
          >
            <div className="flex items-center bg-mainBg bg-opacity-80 p-2 h-10 rounded-lg gap-2">
              <img
                src="/gemSm.png"
                alt="Gems"
                className="sm:mx-1 h-6 object-contain drop-shadow-gems"
              />
              <span className="sm:ml-0.5 font-bold">{gemsCount}</span>
              <span className="hidden sm:inline mr-1">Gems</span>
            </div>
          </TooltipTrigger>
          <TooltipTrigger
            extraClassname="hidden sm:inline"
            content={
              <div>{address}</div>
          }
          >
            <div className="flex items-center text-xs bg-mainBg bg-opacity-80 p-4 h-8 rounded-2xl">{truncatedAddress}</div>
          </TooltipTrigger>
          <GenericButton
            extraClassname="hidden sm:inline"
            onClick={() => disconnect()}
            label="Logout"
          />
        </div>
        <div className="aspect-square min-w-[1.25rem] min-h-[1.25rem] w-6 h-6 sm:hidden">
          <button onClick={() => dispatch(uiSlice.actions.displayMobileMenu())}>
            <HamburgerIconSvg />
          </button>
        </div>
      </div>
      <AccountLevelProgressBar />
    </div>
  );
};

export default NavigationBar;

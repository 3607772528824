import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { SortByList } from './GenericSearchParams';
import getUpdatedSearchParams from '../../utils/sortAndFilters/getUpdatedSearchParams';
import getSearchParamsValue from '../../utils/sortAndFilters/definitions/getSearchParamsValue';
import { SEARCH_PARAMS, SORT_DIRECTION } from '../../utils/consts';

const getSortByNextValue = (currentSortByValue?: string) => {
  if (currentSortByValue) {
    return {
      [SORT_DIRECTION.ascending]: SORT_DIRECTION.descending,
      [SORT_DIRECTION.descending]: null,
    }[currentSortByValue];
  }

  return SORT_DIRECTION.ascending;
};

const SortDirectionLabel = ({
  sortDirection,
}: {
  sortDirection?: string
}) => {
  if (!sortDirection) {
    return <div>–</div>; // TODO nice gradient line?
  }

  return {
    [SORT_DIRECTION.ascending]: <div>Asc.</div>,
    [SORT_DIRECTION.descending]: <div>Desc.</div>,
  }[sortDirection];
};

export default ({
  sortByToDisplay,
}: {
  sortByToDisplay: SortByList
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return sortByToDisplay.map(({ label: valueLabel, searchParam: valueSearchParam }) => {
    const currentSearchParamValue = getSearchParamsValue(SEARCH_PARAMS.sortBy, searchParams, valueSearchParam);

    return (
      <div
        key={valueSearchParam}
        className="relative flex flex-col items-start px-2 cursor-pointer select-none"
        onClick={() => {
          setSearchParams(getUpdatedSearchParams(SEARCH_PARAMS.sortBy, searchParams, valueSearchParam, getSortByNextValue(
            currentSearchParamValue,
          )));
        }}
      >
        <div className="text-sm">{valueLabel}</div>
        <div className="relative">
          <button className="md:text-lg cursor-pointer font-bold">
            <SortDirectionLabel sortDirection={currentSearchParamValue} />
          </button>
        </div>
      </div>
    );
  });
};

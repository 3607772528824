import React from 'react';
import { useLocation } from 'react-router-dom';
import InventoryItemsGridContainer from './InventoryItemsGridContainer';
import { tabs } from './InventoryContainer';
import GenericSearchParams from '../../GenericSearchParams/GenericSearchParams';
import { INVENTORY_FILTER_BY } from '../../../utils/sortAndFilters/definitions/inventoryItems';
import { DISENCHANTMENT_FILTER_BY } from '../../../utils/sortAndFilters/definitions/disenchantmentItems';

export default () => {
  const { pathname } = useLocation();
  const isDisenchantmentActive = pathname.includes(tabs.disenchanting);

  return (
    <>
      <GenericSearchParams
        filtersToDisplay={isDisenchantmentActive ? DISENCHANTMENT_FILTER_BY : INVENTORY_FILTER_BY}
      />
      <InventoryItemsGridContainer />
    </>
  );
};

import { ShopItem } from './additionalTypes';

export default [
  {
    ItemName: 'Bera Punk #3545',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/83fb0de32d60abb7d7cb1cb32243d9a0.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3545',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3547',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/ebeaf3f5587befb8e8f2e400a4591ce1.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3547',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3558',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/faf20e06c8a411bda44ee673fe0c78b4.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3558',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3561',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/873a5781c4eaa7ac3c7aa148bf15bdc8.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3561',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3562',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/199ba0aa16f4d2b8d03c3ba850440d39.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3562',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3566',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/b22e7fa3a3ba8276d991d4a4854c2b0c.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3566',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3567',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/33ca2b686b9b39e216996f3516a97c07.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3567',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3574',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/fd1b1a353b542a1ba1a5b731ac8d31ab.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3574',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3588',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/4b17782cbf287fd63504558d33c8622a.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3588',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3591',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/a5e9cd78bc133e02a5141a92193095a8.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3591',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 2000000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #285',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/fcdac774d9a95e1359ec49b30e352acc.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/285',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #372',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/dee5789f4efc57a066b2621bfc27bb2b.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/372',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #397',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/07b5dfbf2fe89c184ee3ba479f399729.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/397',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #400',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/e13516c85a38ee536b81a475069c4967.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/400',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #455',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/c32b6d163d40b2125303cf2fac8debbf.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/455',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #556',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/a6eba2857d695af171c10356eb5ebeb1.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/556',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #654',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/2204906338db57f9424ce78ca955d0c7.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/654',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #672',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/90ef265390d96f56eb4c1a651c54f33a.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/672',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #674',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/b6fbc7f19dae87320dc2ad36bc31cdc8.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/674',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
  {
    ItemName: 'Berautistics #697',
    ItemDescription: 'The most Berautistic PFP on Berachain',
    ItemImage: {
      kind: 'link',
      value: 'https://i.seadn.io/s/raw/files/b0791ac9196836cc69f0828b95fd892c.png?auto=format&dpr=1&w=200',
    },
    ItemType: 'NFT',
    OpenSeaLink: 'https://opensea.io/assets/arbitrum/0x3f979dde509d2aedc99e9159f40e0d0d3e9ddb56/697',
    ItemCollectionName: 'Berautistics',
    ItemPrice: 3500000,
    Unlimited: false,
  },
] as ShopItem[];

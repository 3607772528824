import React, { useState } from 'react';
import FilterBy from './FilterBy';
import SortBy from './SortBy';

const styles = {
  wrapperContainer: 'relative flex flex-row z-10 pb-2 shadow-bottom md:flex-wrap',
  filtersContainer: (isOpen: boolean) => `${isOpen ? 'flex-row' : 'hidden md:!flex'} relative flex flex-row text-white`,
  filterBy: 'text-sm cursor-default mr-2 w-7 leading-[0.9rem] mt-[0.2rem] italic drop-shadow-inheritColorExtraExtraSmall',
};

export type FilterValue = {
  label: string
  searchParam: string
}

export type FilterList = Array<{
  label: string
  searchParam: string
  values: Array<FilterValue>
  displayCondition?: {
    key: string,
    value: string
  }
}>

export type SortByList = Array<{
  label: string
  searchParam: string
}>

const MOBILE_TABS = {
  filter: 'FILTER',
  sort: 'SORT',
} as const;

const Divider = () => <div className="relative flex flex-col w-[2px] justify-between ml-3 mr-5">
  <div className="h-4 bg-gradient-to-b from-transparent to-white/50" />
  <div className="h-full bg-white/50" />
  <div className="h-4 bg-gradient-to-t from-transparent to-white/50" />
</div>;

const MobileTabButton = ({
  kind,
  onClick,
}: {
  kind: ValueOf<typeof MOBILE_TABS>
  onClick: () => void
}) => <div
  className={`md:hidden relative w-4 flex justify-center items-center border-[1px] border-white rounded cursor-pointer h-[48px]
      ${kind === MOBILE_TABS.filter && '-ml-[0.5rem]'}
    `}
  onClick={onClick}
>
  <div className="absolute w-12 text-center text-white -rotate-90 text-[0.6rem]">{`${kind} BY`}</div>
</div>;

export default ({
  filtersToDisplay,
  sortByToDisplay,
  extraClassname = 'px-4 mt-4 lg:px-8 lg:mt-6',
}: {
  filtersToDisplay: FilterList
  sortByToDisplay?: SortByList
  extraClassname?: string
}) => {
  const [openMobileTab, setOpenMobileTab] = useState<ValueOf<typeof MOBILE_TABS>>(filtersToDisplay ? MOBILE_TABS.filter : MOBILE_TABS.sort);

  return (
    <div className={`${styles.wrapperContainer} ${extraClassname}`}>
      {sortByToDisplay && (
      <>
        {openMobileTab !== MOBILE_TABS.sort && <MobileTabButton
          kind={MOBILE_TABS.sort}
          onClick={() => setOpenMobileTab(MOBILE_TABS.sort)}
        />}
        {sortByToDisplay && <div className={styles.filtersContainer(openMobileTab === MOBILE_TABS.sort)}>
          <div className={styles.filterBy}>Sort by: </div>
          <SortBy sortByToDisplay={sortByToDisplay} />
        </div>}
        <Divider />
        {openMobileTab !== MOBILE_TABS.filter && <MobileTabButton
          kind={MOBILE_TABS.filter}
          onClick={() => setOpenMobileTab(MOBILE_TABS.filter)}
        />}
      </>
      )}
      {filtersToDisplay && <div className={styles.filtersContainer(openMobileTab === MOBILE_TABS.filter)}>
        <div className={styles.filterBy}>Filter by: </div>
        <FilterBy filtersToDisplay={filtersToDisplay} />
      </div>}
    </div>
  );
};

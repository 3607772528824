import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet, coinbaseWallet, rainbowWallet, walletConnectWallet, rabbyWallet, bitgetWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createClient } from 'viem';
import { http, createConfig } from 'wagmi';
import { berachainTestnetbArtio, mainnet, sepolia } from 'wagmi/chains';

const SUPPORTED_NETWORKS = {
  mainnet,
  sepolia,
  bartio: berachainTestnetbArtio,
} as Record<string, typeof mainnet | typeof sepolia | typeof berachainTestnetbArtio>;

/** The main network that the app is deployed to. */
export const PRIMARY_NETWORK = SUPPORTED_NETWORKS[process.env.REACT_APP_CHAIN_NAME ?? ''] ?? SUPPORTED_NETWORKS.bartio;

export default createConfig({
  chains: [PRIMARY_NETWORK],
  connectors: connectorsForWallets(
    [
      {
        groupName: 'Recommended',
        wallets: [rabbyWallet, bitgetWallet, metaMaskWallet, coinbaseWallet, rainbowWallet, walletConnectWallet],
      },
    ],
    {
      appName: 'Beramonium Gemhunters',
      projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? '',
    },
  ),
  client: ({ chain }) => createClient({ chain, transport: http() }),
});

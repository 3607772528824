import React, { useEffect } from 'react';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import { useAppSelector } from '../../../store/hooks';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import { ShopItem } from '../../../utils/additionalTypes';
import ShopGridItem from './ShopGridItem';
import filterArrayBySearchParamsReworked from '../../../utils/sortAndFilters/filterArrayBySearchParams';
import { SHOP_FILTER_BY_MAP } from '../../../utils/sortAndFilters/definitions/shopItems';

export default () => {
  const shopItems = useAppSelector((state) => state.shop.shopItems) as Array<ShopItem>;
  const isFetchingData = useAppSelector((state) => state.shop.isFetchingData) as boolean;

  useEffect(() => {
    if (!shopItems) {
      modalService.pushModal(<ErrorModal error={new Error('empty shop!!')} />); // TODO better error handling
    }
  }, [shopItems]);

  if (!shopItems) {
    return null;
  }

  const shopItemsWithoutMainPrize = shopItems.filter((shopItem) => !shopItem.IsMainShopPrize);

  const filteredShopItems = filterArrayBySearchParamsReworked<Array<ShopItem>>(shopItemsWithoutMainPrize, SHOP_FILTER_BY_MAP);

  const itemsSorted = [...filteredShopItems];
  // First sort by price, then by item collection type
  itemsSorted.sort((a, b) => {
    const byPrice = () => b.ItemPrice - a.ItemPrice;
    const byCollection = () => ((b.ItemCollectionName ?? '').localeCompare(a.ItemCollectionName ?? ''));
    const byName = () => ((a.ItemName ?? '').localeCompare(b.ItemName ?? '', 'en', { numeric: true }));

    return byPrice() || byCollection() || byName();
  });

  return <GenericItemGrid
    extraClassname="mt-2 mb-4 px-4 lg:px-8"
    aspectRatio={'1/1.3'}
    minItemSizeInRem={8}
    isLoading={isFetchingData}
    itemsToDisplay={itemsSorted.map((shopItem) => (
      <ShopGridItem
        key={shopItem.ItemID}
        imgSrc={shopItem.ItemImage.kind === 'name' ? `/inventoryItems/${shopItem.ItemImage.value}.jpeg` : shopItem.ItemImage.value}
        shopItem={shopItem}
      />
    ))}
  />;
};
